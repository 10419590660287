<template>
    <div class="text-center custom-banner">
        <!-- <h6 class="banner-text mb-0"><a class="text-light" href="/menu/happy-hour">Check out our Happy Hour Menu!</a></h6> -->
        <h6 class="banner-text mb-0"><a class="text-light" target="_blank" href="https://www.opentable.com/r/kosmos-reservations-walpole?restref=1239994&lang=en-US&ot_source=Restaurant%20website">Join us for Brunch on Easter Sunday!</a></h6>
        <!-- <h6 class="banner-text mb-0">Checkout our lunch additions every Saturday & Sunday from 12-3pm</h6> -->
    </div>
</template>

<script>
export default {
    name: 'HeaderBanner'
}
</script>